@import 'styles/index';

body {
  margin: 0;
  padding: 0;
  background-color: $black;
  overflow: hidden;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;

  @include breakpoint($xxl) {
    font-size: 15px;
  }

  @include breakpoint($xl) {
    font-size: 14px;
  }

  @include breakpoint($lg) {
    font-size: 13px;
  }

  @include breakpoint($md) {
    font-size: 12px;
  }

  @include breakpoint($sm) {
    font-size: 11px;
  }
  @include breakpoint($xs) {
    font-size: 10px;
  }

  @include breakpoint($xxs) {
    font-size: 10px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #3c3c3c;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #8a8a8a;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}
