.splash {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 50px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
